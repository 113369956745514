import KeywordsSelectPane from "@/components/keywords-select-section/keywords-select-pane";
import Input from "@/components/input";

export default {
    name: "keywords-select-section",
    components: {
        "keywords-select-pane": KeywordsSelectPane,
        "neo-input": Input,
    },
    props: {
        keywords: {
            type: Array,
            required: true,
        },
        selectedData: {
            type: Object,
            default() {
                return {
                    keywords: {},
                    nearCount: null,
                };
            },
        },
    },
    data() {
        return {
            selectedKeywords: {},
            nearnessNumber: 2,
            nearnessMin: 2,
            nearnessMax: 200,
        };
    },
    created() {
        if (this.selectedData.keywords != null && Object.keys(this.selectedData.keywords).length > 0) {
            this.mergeKeywords(this.selectedKeywords, this.selectedData.keywords);
        }
        this.nearnessNumber = this.selectedData.nearCount || this.nearnessMin;
    },
    methods: {
        // on keywords select change
        updateSelectedSetItems(selectedData) {
            this.selectedKeywords[selectedData.id] = selectedData.result;
            this.triggerOutputResult();
        },
        // on nearness change
        nearnessChange() {
            const rounded = Math.round(this.nearnessNumber);
            if (rounded > this.nearnessMax) {
                this.nearnessNumber = this.nearnessMax;
            } else if (rounded < this.nearnessMin) {
                this.nearnessNumber = this.nearnessMin;
            } else {
                this.nearnessNumber = rounded;
            }

            this.triggerOutputResult();
        },
        // emit result to parent
        triggerOutputResult() {
            const output = {
                nearCount: this.nearnessNumber,
                keywords: this.getNotEmptyKeywords(this.selectedKeywords),
            };
            this.$emit("outputChange", output);
        },
        mergeKeywords(existingKeywords, newKeywords) {
            Object.keys(newKeywords).forEach((key) => {
                if (newKeywords[key].length > 0) {
                    existingKeywords[key] = newKeywords[key].slice();
                }
            });
        },
        getNotEmptyKeywords(keywords) {
            const result = {};
            Object.keys(keywords).forEach((key) => {
                if (keywords[key].length > 0) {
                    result[key] = keywords[key].slice();
                }
            });
            return result;
        },
    },
};
