export default {
    name: "keywords-select-pane",
    components: {},
    props: {
        keywordSetItem: {
            type: Object,
            required: true,
        },
        selectedKeywords: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            expanded: false,
            options: [],
            selected: [],
            allSelected: false,
            indeterminate: false,
        };
    },
    created() {
        this.options = this.words.map((word) => {
            return {text: word, value: word};
        });
        this.selected = this.selectedKeywords.slice();
    },
    watch: {
        selected(newValue, oldValue) {
            if (newValue.length === 0) {
                this.indeterminate = false;
                this.allSelected = false;
            } else if (newValue.length === this.options.length) {
                this.indeterminate = false;
                this.allSelected = true;
            } else {
                this.indeterminate = true;
                this.allSelected = false;
            }
            this.processOutput();
        },
    },
    computed: {
        paneId() {
            return this.keywordSetItem._id;
        },
        keywordSetName() {
            return this.keywordSetItem.keyword_set_name;
        },
        words() {
            return this.keywordSetItem.words.map((item) => item.word);
        },
        totalItemsCount() {
            return this.words.length;
        },
        selectedCount() {
            return this.selected.length;
        },
        isAllSelected() {
            return this.options.length === this.selectedCount;
        },
        selectedToAllText() {
            return `${this.selectedCount}/${this.totalItemsCount} selected`;
        },
    },
    methods: {
        toggleExpanded() {
            this.expanded = !this.expanded;
        },
        toggleAll(event) {
            const checked = event.target.checked;
            this.selected = checked ? this.options.map((option) => option.value).slice() : [];
        },
        processOutput() {
            const outputResult = this.selected.map((item) => item);
            this.$emit("resultUpdated", {id: this.keywordSetItem._id, result: outputResult});
        },
    },
};
