import { render, staticRenderFns } from "./keywords-select-section.html?vue&type=template&id=25d6b8cb&scoped=true&"
import script from "./keywords-select-section.js?vue&type=script&lang=js&"
export * from "./keywords-select-section.js?vue&type=script&lang=js&"
import style0 from "./keywords-select-section.scss?vue&type=style&index=0&id=25d6b8cb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d6b8cb",
  null
  
)

export default component.exports