import { render, staticRenderFns } from "./keywords-select-pane.html?vue&type=template&id=616654cb&scoped=true&"
import script from "./keywords-select-pane.js?vue&type=script&lang=js&"
export * from "./keywords-select-pane.js?vue&type=script&lang=js&"
import style0 from "./keywords-select-pane.scss?vue&type=style&index=0&id=616654cb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "616654cb",
  null
  
)

export default component.exports