import KeywordsSelectSection from "@/components/keywords-select-section";
import Button from "@/components/button";
import Badge from "@/components/badge";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {mapGetters} from "vuex";
import {showToast} from "@/utils/functions";

export default {
    name: "keywords-selector-tool",
    props: {
        selectedData: {
            type: Object,
            default() {
                return {
                    keywords: {},
                    nearCount: null,
                };
            },
        },
        disabled: false,
    },
    components: {
        "keywords-select-section": KeywordsSelectSection,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-button": Button,
        "neo-badge": Badge,
    },
    data() {
        return {
            keywordSetsIdToItemMap: new Map(),
            selectedKeywordsData: {
                keywords: {},
                nearCount: null,
            },
            isLoading: false,
        };
    },
    beforeMount() {
        this.loadEntities();
    },
    created() {
        if (this.selectedData != null) {
            this.selectedKeywordsData = this.cloneSelectedKeywordsData(this.selectedData);
        }
    },
    watch: {
        selectedData(newVal, oldVal) {
            this.selectedKeywordsData = this.cloneSelectedKeywordsData(newVal);
        },
        errorMessage(newValue, oldValue) {
            showToast(newValue);
        },
    },
    computed: {
        ...mapGetters({
            keywordProductMapEntities: "getKeywordProductMapEntities",
            errorMessage: "getErrorMessage",
        }),

        selectedGroups() {
            const result = [];
            const keywords = this.selectedKeywordsData?.keywords;
            const keywordsMap = this.keywordSetsIdToItemMap;
            const keywordsLength = keywords != null ? Object.keys(keywords).length : 0;
            if (keywordsLength === 0 || keywordsMap.size === 0) {
                return result;
            }
            Object.keys(keywords).forEach((key) => {
                const value = keywords[key];
                if (value.length > 0) {
                    result.push({
                        id: key,
                        name: keywordsMap.get(key).keyword_set_name,
                        count: value.length,
                    });
                }
            });
            return result;
        },
        totalWordsSelected() {
            if (this.selectedKeywordsData?.keywords == null) {
                return 0;
            }
            return Object.keys(this.selectedKeywordsData.keywords).reduce((result, key) => {
                const value = this.selectedKeywordsData.keywords[key];
                result += value.length;
                return result;
            }, 0);
        },

        getProductKey() {
            if (this.$store.getters.getProduct) this.loadEntities();
        },

        product_id() {
            const product = this.$store.getters.getProduct;
            return product?.key;
        },
    },
    methods: {
        async loadEntities() {
            try {
                this.isLoading = true;
                await this.$store.dispatch("loadKeywordEntities");
                if (this.product_id) await this.$store.dispatch("loadKeywordEntitiesByProduct", this.product_id);

                this.keywordSetsIdToItemMap = this.createKeywordsIdToItemMap(this.keywordProductMapEntities || []);
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        applyChanges(newData) {
            this.selectedKeywordsData = {
                keywords: this.cloneKeywords(newData.keywords),
                nearCount: newData.nearCount,
            };
            this.emitDataToParent();
        },
        showKeywordsSelectModal() {
            if (!this.disabled) this.$modal.show("keywords-selector-tool-modal");
        },
        hideKeywordsSelectModal() {
            this.$modal.hide("keywords-selector-tool-modal");
        },
        keywordsToMap(keywords) {
            const selectedMap = new Map();
            Object.keys(keywords).forEach((key) => {
                selectedMap.set(key, keywords[key].slice());
            });
            return selectedMap;
        },
        fromMapToKeysArray(mapValues) {
            const arrayResult = [];
            mapValues.forEach((value, key) => {
                arrayResult[key] = value.slice();
            });
            return arrayResult;
        },
        cloneKeywords(keywords) {
            const result = {};
            Object.keys(keywords).forEach((key) => {
                result[key] = keywords[key].slice();
            });
            return result;
        },
        createKeywordsIdToItemMap(allKeywords) {
            const resultMap = new Map();
            allKeywords.forEach((item) => {
                resultMap.set(item._id, item);
            });
            return resultMap;
        },
        // click on cross icon on certain keyword set
        deselectKeywordsSet(keywordSetId) {
            if (!this.disabled) {
                const {[keywordSetId]: removedValue, ...filteredObject} = this.selectedKeywordsData.keywords;
                this.selectedKeywordsData.keywords = filteredObject || {};
                this.emitDataToParent();
            }
        },
        emitDataToParent() {
            this.$emit("keywordsSelected", this.cloneSelectedKeywordsData(this.selectedKeywordsData));
        },
        cloneSelectedKeywordsData(selectedKeywordsData) {
            return {
                keywords: this.cloneKeywords(selectedKeywordsData.keywords),
                nearCount: selectedKeywordsData.nearCount,
            };
        },
        mergeKeywords(existingKeywords, newKeywords) {
            Object.keys(newKeywords).forEach((key) => {
                if (newKeywords[key].length > 0) {
                    existingKeywords[key] = newKeywords[key].slice();
                }
            });
        },
    },
};
